:root {
  --primary-color: #FFED00;
  --primary-hover-color: #F2E100;
  --text-color: #2B2B2B;
  --text-color-gray: #BABABA;
  --text-rgb-color: 43, 43, 43;
  --gray-color: #6E6E6E;
  --semi-gray-color: #EDEDED;
  --light-gray-color: #F5F5F5;
  --border-color: #757575;
  --white-color: #FFFFFF;
  --button-hover-color: #F2F2F2;
  --black-color: #000000;
  --red-color: #E2021A;
  --green-color: #4AAD40;
  --celadon-green-color: #238C96;
  --light-celadon-green-color: #BDDCDF;
  --orange-color: #F5802A;
  --apple-color: #4FAE47;
  --focus-border-color: #468A94;
  --button-border-color: #2B2B2B;
  --header-gray-color: #757575;
  --pagination-gray-color: #D8D8D8;
  --input-bg-color: #F5F5F5;
  --circle-geofence-border-color: #757575;
  --circle-geofence-bg-color: #757575;
  --user-status-color: #4EAE46;
  --bright-grey: #F1F1F1;
  --dark-grey: #6E6E6E;
  --neutral-white-color: #D4D4D4;
  --steps-background-color: #F8F8F8;
  --error-explain-color: #FF4D4F;
  --green-100-color: #DBEFD9;
  --gray-100-color: #E5E5E5;
  --tertiary-color: #E9BAC0;
}
